export const colors = [
  0xF5C1BB,
  0xFC3E77,
  0xD49E9E,
  0xCCEEEE,
  0x594F57,
  0x888899,
  0xEC5685,
  0x8AD9EC,
  0x109FB1,
  0x9CCFE7,
  0x977FD7,
  0xF5A9CB,
  0xFFFFC2,
  0xFA6D6F,
  0xFA8F6F
]
