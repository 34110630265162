<template>
  <Tap />
</template>

<script>
import Tap from './components/Tap.vue'

export default {
  components: {
    Tap
  }
}
</script>

<style lang="stylus">
body
  margin 0
  overflow hidden
</style>
