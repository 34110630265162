export const audios = {
  sound1: require('@/assets/tap/audios/1.mp3'),
  sound2: require('@/assets/tap/audios/2.mp3'),
  sound3: require('@/assets/tap/audios/3.mp3'),
  sound4: require('@/assets/tap/audios/4.mp3'),
  sound5: require('@/assets/tap/audios/5.mp3'),
  sound6: require('@/assets/tap/audios/6.mp3'),
  sound7: require('@/assets/tap/audios/7.mp3'),
  sound8: require('@/assets/tap/audios/8.mp3'),
  sound9: require('@/assets/tap/audios/9.mp3'),
  sound10: require('@/assets/tap/audios/10.mp3'),
  sound11: require('@/assets/tap/audios/11.mp3'),
  sound12: require('@/assets/tap/audios/12.mp3'),
  sound13: require('@/assets/tap/audios/13.mp3'),
  sound14: require('@/assets/tap/audios/14.mp3'),
  sound15: require('@/assets/tap/audios/15.mp3'),
  sound16: require('@/assets/tap/audios/16.mp3'),
  sound17: require('@/assets/tap/audios/17.mp3'),
  sound18: require('@/assets/tap/audios/18.mp3'),
  sound19: require('@/assets/tap/audios/19.mp3'),
  sound20: require('@/assets/tap/audios/18.mp3'),
  sound21: require('@/assets/tap/audios/17.mp3'),
  sound22: require('@/assets/tap/audios/16.mp3'),
  sound23: require('@/assets/tap/audios/15.mp3'),
  sound24: require('@/assets/tap/audios/14.mp3'),
  sound25: require('@/assets/tap/audios/13.mp3'),
  sound26: require('@/assets/tap/audios/12.mp3'),
  sound27: require('@/assets/tap/audios/11.mp3'),
  sound28: require('@/assets/tap/audios/10.mp3'),
  sound29: require('@/assets/tap/audios/9.mp3'),
  sound30: require('@/assets/tap/audios/8.mp3'),
  sound31: require('@/assets/tap/audios/7.mp3'),
  sound32: require('@/assets/tap/audios/6.mp3')
}

export const bgm = require('@/assets/tap/audios/薄群青.mp3')
